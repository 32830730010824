body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Londrina", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "LondrinaBold";
  src: local("LondrinaBold"),
    url("./fonts/Londrina/LondrinaSolid-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LondrinaLight";
  src: local("LondrinaLight"),
    url("./fonts/Londrina/LondrinaSolid-Light.ttf") format("truetype");
}
