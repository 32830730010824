@font-face {
  font-family: "LondrinaBold";
  src: local("LondrinaBold"),
    url("./fonts/Londrina/LondrinaSolid-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LondrinaLight";
  src: local("LondrinaLight"),
    url("./fonts/Londrina/LondrinaSolid-Light.ttf") format("truetype");
}

.App {
  text-align: center;
  margin-block-end: 24px;
  margin-block-start: 24px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title {
  font-family: "LondrinaBold";
}

.default-font {
  font-family: "LondrinaLight";
}

.paper {
  background-color: #383535;
  width: -webkit-fill-available;
}

.outer-box {
  display: grid;
  min-width: fit-content;
}

.card {
  margin: 0 auto;
  font-family: "LondrinaLight";
}

.rater {
  margin: 0 auto;
  display: inline-flex;
  position: relative;
  cursor: pointer;
}

.css-1wa3eu0-placeholder {
  font-family: Arial;
}

.css-2b097c-container {
  font-family: Arial;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
